var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "centered-item",
      staticStyle: { height: "50px" },
      style: { maxWidth: _vm.isMobile ? "450px" : "650px" },
      attrs: { "no-gutters": "" },
    },
    [
      _c("v-col", { attrs: { cols: "3" } }, [
        _c("div", { staticClass: "text-item" }, [
          _vm._v(_vm._s(_vm.$t("AsSeenOn"))),
        ]),
      ]),
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/auto-blog.webp"),
              height: "46.88",
              contain: "",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/market-watch.webp"),
              height: "25",
              contain: "",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/yahoo-finance.webp"),
              height: "43.13",
              contain: "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }